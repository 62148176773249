import { InjectedConnector } from '@web3-react/injected-connector'
import { AddressSet } from 'contracts/sets/index'
import Web3 from 'web3'
import { provider } from 'web3-core'

export enum NETWORK_TYPE {
  ETHMainnet = 'ETHMainnet',
  BSCMainnet = 'BSCMainnet',
}

export const chainIdMap = {
  [NETWORK_TYPE.ETHMainnet]: 1,
  [NETWORK_TYPE.BSCMainnet]: 56,
}

export const networks = [
  chainIdMap[NETWORK_TYPE.ETHMainnet],
  chainIdMap[NETWORK_TYPE.BSCMainnet],
]

export const injectedConnector = new InjectedConnector({
  supportedChainIds: networks,
})

export class Provider extends Web3 {
  static defaultNetwork = NETWORK_TYPE.BSCMainnet;

  constructor(connector: provider) {
    super(connector)
    this.currentNetwork = Provider.defaultNetwork
  }

  currentNetwork: NETWORK_TYPE

  set setCurrentNetworkType(networkType: NETWORK_TYPE) {
    this.currentNetwork = networkType
  }

  get contractSet() {
    return new AddressSet(this.currentNetwork).set
  }
}
