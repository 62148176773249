import { NETWORK_TYPE, SetInterface } from './index'

export const web3Sets: { [key in NETWORK_TYPE]?: SetInterface } = {
  ETHMainnet: {
    erc20Token: '0x18f04e1BFA5C0F28DB6AB07dA4E7b01cD40ed349',
    vptToken: '0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3',
    voting: '0xa54ad71c4cd8e9c514D5f1269cf36ED3d7C63eb9',
    bridge: '0xe66025BD8eF67d8c1Cef8125f779b1B60c1C462f',
  },
  BSCMainnet: {
    erc20Token: '0x18f04e1BFA5C0F28DB6AB07dA4E7b01cD40ed349',
    vptToken: '0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3',
    voting: '0xa54ad71c4cd8e9c514D5f1269cf36ED3d7C63eb9',
    bridge: '0xf22A42B40a4148513406C3613b9d32f2106717CB',
  },
}

export const thorSets = {
  stacking: '0x29C6777D68AD634644Bf0A990e835904Fe2890EB',
  hai: '0xaCc280010B2EE0efc770BCE34774376656D8cE14',
}
