import { web3Sets } from './sets'

export enum NETWORK_TYPE {
  ETHMainnet = 'ETHMainnet',
  BSCMainnet = 'BSCMainnet',
}

export class AddressSet {
  networkType: NETWORK_TYPE
  set: EthAddress | undefined

  constructor(networkType: NETWORK_TYPE) {
    this.networkType = networkType
    this.set = web3Sets[networkType]
  }
}

export class EthAddress implements SetInterface {
  erc20Token: string
  vptToken: string
  voting: string
  bridge: string

  constructor(erc20Token: string, vptToken: string, voting: string, bridge: string) {
    this.erc20Token = erc20Token
    this.vptToken = vptToken
    this.voting = voting
    this.bridge = bridge
  }
}

export interface SetInterface {
  erc20Token: string
  vptToken: string
  voting: string
  bridge: string
}
