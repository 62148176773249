import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import React, { FC } from 'react'
import EthWalletLogo from 'assets/icons/EthWalletLogo.svg'
import VetWalletLogo from 'assets/icons/VetWalletLogo.svg'
import EthWalletActiveLogo from 'assets/icons/EthWalletActiveLogo.svg'
import { colors } from 'utils/mui'
import { useStyles } from './styles'
import { transformAddress } from 'utils/NumberFormatter'

export enum WalletTypes {
  EthWallet,
  VetWallet,
}

interface ButtonProps extends MuiButtonProps {
  walletType: WalletTypes
  activeThorAccount: string
  activeWeb3Account: string
  activateThorAccount: () => void
  activateWeb3Account: () => void
}

const WalletButton: FC<ButtonProps> = ({
  walletType,
  activeThorAccount,
  activeWeb3Account,
  activateThorAccount,
  activateWeb3Account,
}: ButtonProps) => {
  const { buttonStyles } = useStyles()

  return (
    <Button
      onClick={walletType === WalletTypes.EthWallet ? activateWeb3Account : activateThorAccount}
      startIcon={
        walletType === WalletTypes.EthWallet ? (
          <div style={{ height: 17, width: 17, borderRadius: '50%' }}>
            <img src={activeWeb3Account ? EthWalletActiveLogo : EthWalletLogo} />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: activeThorAccount ? colors.primaryGreen : colors.navBarIconBg,
              height: 17,
              width: 17,
              borderRadius: '50%',
            }}
          >
            <img style={{ padding: '4px 0' }} src={VetWalletLogo} />
          </div>
        )
      }
      variant="outlined"
      classes={{ root: buttonStyles }}
    >
      <span
        style={{
          color:
            (Boolean(activeWeb3Account) && walletType === WalletTypes.EthWallet) ||
            (Boolean(activeThorAccount) && walletType === WalletTypes.VetWallet)
              ? colors.primaryGreen
              : colors.walletButtonTextColor,
        }}
      >
        {Boolean(activeWeb3Account) && walletType === WalletTypes.EthWallet
          ? transformAddress(activeWeb3Account)
          : Boolean(activeThorAccount) && walletType === WalletTypes.VetWallet
            ? transformAddress(activeThorAccount)
            : 'common_wallet_button_text'.localized()}
      </span>
    </Button>
  )
}

export default WalletButton
