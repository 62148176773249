import { client } from 'apollo/client'
import { GET_PROPOSALS } from 'apollo/queries'
import Big from 'big.js'
import { allowance, balanceOf, stakedBalance } from 'contracts/thor/contract'
import { abiERC20, abiVoting } from 'contracts/web3'
import { AbiERC20 } from 'contracts/web3/typings/abi_ERC20'
import { AbiVoting } from 'contracts/web3/typings/abi_voting'
import React, { createContext, useState } from 'react'
import { Provider } from 'services/Provider'
import { VPT_PRECISION } from './NumberFormatter'

export const StoreContext = createContext<any>(null)

export const TRANSACTION_LOCALSTORAGE_KEY = 'TRANSACTIONS'
const transactionsInitialState =
  (JSON.parse(localStorage.getItem(TRANSACTION_LOCALSTORAGE_KEY) as string) as Transaction[]) || []

export type TransactionStatus = 'success' | 'canceled' | 'pending' | 'failed'
export type TransactionChain = 'eth' | 'vechain'
export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

export interface Transaction {
  status: TransactionStatus
  hash: string
  summary: string
  chainId: number
  chain: TransactionChain
  addedTime: number
  lastCheckedBlockNumber?: number
  receipt?: SerializableTransactionReceipt | Connex.Thor.Transaction
}
export function createTransaction(hash: string, chain: TransactionChain, summary: string, chainId = 1): Transaction {
  return {
    hash: hash,
    status: 'pending',
    chainId: chainId,
    chain: chain,
    addedTime: new Date().getTime(),
    summary: summary,
  }
}
export interface BlockInfo {
  data: { [key in TransactionChain]: { chainId: number; blockNumber: number }[] }
}

export const StoreProvider = ({ children }: any) => {
  const [thorAccount, setThorAccount] = useState<string>('')
  const [thorSigner, setThorSigner] = useState<any>('')
  const [haiBalance, setHaiBalance] = useState<string>('')
  const [haiStake, setHaiStake] = useState<string>('')
  const [web3Account, setWeb3Account] = useState<string>('')
  const [proposal, setProposal] = useState<any>()
  const [vptAllowance, setVptAllowance] = useState<string>()
  const [hapiBalance, setHapiBalance] = useState<string>()
  const [haiAllowance, setHaiAllowance] = useState<string>()
  const [unclaimed, setUnclaimed] = useState<any>()
  const [activeProposalPreview, setActiveProposalPreview] = useState<
    { id: string; title: string; deadlineBlock: string; amount: string }[]
  >()
  const [inactiveProposalPreview, setInactiveProposalPreview] = useState<
    { id: string; title: string; deadlineBlock: string; amount: string }[]
  >()
  const [vptBalance, setVptBalance] = useState<string>('')
  const [transactions, setTransactions] = useState<Transaction[]>(transactionsInitialState)
  const [blockNumberInfo, setBlockNumberInfo] = useState<BlockInfo>({ data: { eth: [], vechain: [] } })
  const [transactionModalOpen, setTransactionModalOpen] = useState<boolean>(false)
  const [withdrawableBalance, setWithdrawableBalance] = useState<string>('0')

  const updateEthBalances = async (account: any, library: any) => {
    const provider: Provider = new Provider(library.provider)

    if (library && provider && provider.contractSet && account) {
      const contractERC20 = (new provider.eth.Contract(abiERC20, provider.contractSet.vptToken) as any) as AbiERC20
      const contractERC20Token = (new provider.eth.Contract(abiERC20, provider.contractSet.vptToken) as any) as AbiERC20
      const balanceVPT = await contractERC20.methods
        .balanceOf(account)
        .call()
        .catch((e) => {
          throw new Error(`getBalance failed ${e.message}`)
        })

      const contractVoting = (new provider.eth.Contract(abiVoting, provider.contractSet.voting) as any) as AbiVoting

      setVptBalance(new Big(balanceVPT).div(VPT_PRECISION).toFixed())

      const proposals = await client.query({
        query: GET_PROPOSALS,
      })
      const block = await provider.eth.getBlockNumber()
      const proposalAmounts = proposals.data && proposals.data.proposalAmounts
      const createdProposals =
        proposals.data &&
        proposals.data.createdProposals.map((item: any) =>
          Object.assign(
            item,
            proposalAmounts.find((prItem: any) => prItem.id === item.id),
          ),
        )
      setActiveProposalPreview(createdProposals.filter((item: any) => item.deadlineBlock > block))
      setInactiveProposalPreview(createdProposals.filter((item: any) => item.deadlineBlock < block))

      const allowed = await contractERC20Token.methods
        .allowance(account, provider.contractSet.voting)
        .call()
        .catch((e) => {
          throw new Error(`allowance failed ${e.message}`)
        })
      setVptAllowance(allowed)
      if (!proposal) return
      const unclaimed = await contractVoting.methods
        .tokensInProposalOf(proposal._proposalId, account)
        .call()
        .catch((e) => {
          throw new Error(`allowance failed ${e.message}`)
        })
      setUnclaimed(unclaimed)

      const proposalInfo = await contractVoting.methods
        .proposalById(proposal._proposalId)
        .call()
        .catch((e) => {
          throw new Error(`createProposal failed ${e.message}`)
        })
      setProposal(proposalInfo)
    }
  }
  const updateVechainBalances = async () => {
    const balance = await balanceOf(thorAccount)
    await setHaiBalance(balance?.decoded?.[0])
    const staked = await stakedBalance(thorAccount)
    await setHaiStake(staked?.decoded?.[0])
    await allowance(thorAccount, setHaiAllowance)
  }
  const updateTransactions = (transaction: Transaction) => {
    let newState: Transaction[]
    const index = transactions.findIndex((el) => el.hash === transaction.hash)
    if (index >= 0) {
      newState = [...transactions]
      newState[index] = transaction
    } else {
      newState = [...transactions, transaction]
    }
    setTransactions(newState)
    localStorage.setItem(TRANSACTION_LOCALSTORAGE_KEY, JSON.stringify(newState))
  }

  const store = {
    comet: [thorAccount, setThorAccount],
    thorSigner: [thorSigner, setThorSigner],
    haiBalance: [haiBalance, setHaiBalance],
    haiStaked: [haiStake, setHaiStake],
    metamask: [web3Account, setWeb3Account],
    activeProposal: [proposal, setProposal],
    activeVptAllowance: [vptAllowance, setVptAllowance],
    activeHaiAllowance: [haiAllowance, setHaiAllowance],
    vptBalance: [vptBalance, setVptBalance],
    activePreview: [activeProposalPreview, setActiveProposalPreview],
    inactivePreview: [inactiveProposalPreview, setInactiveProposalPreview],
    currentUnclaimedStatus: [unclaimed, setUnclaimed],
    transactions: [transactions, updateTransactions, setTransactions],
    blockInfo: [blockNumberInfo, setBlockNumberInfo],
    hapi: [hapiBalance, setHapiBalance],
    updateEthBalances: updateEthBalances,
    updateVechainBalances: updateVechainBalances,
    transactionModal: [transactionModalOpen, setTransactionModalOpen],
    withdrawable: [withdrawableBalance, setWithdrawableBalance],
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
